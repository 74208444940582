<!--  -->
<template>
    <div class='subject-com-rank-link' :class="[type, {'active': active}]">
    </div>
</template>

<script>
    export default {
        name: '',
        props: {
            active: [Boolean, Number],
            type: {
                type: String,
                default: 'right'
            }
        },
        data() {
            return {

            };
        },
        methods: {

        },
        created() {

        },
        components: {
        },
        computed: {},
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .subject-com-rank-link{
        width: 114px;
        height: 114px;
        // border: 14px solid #FFE973;
        border: 14px solid #9EC8FF;
        
    }
    .subject-com-rank-link.left{
        border-radius: 100% 0 0 100%;
        border-right: 0px;
    }
    .subject-com-rank-link.right{
        border-radius: 0px 100% 100% 0px;
        border-left: 0px;
    }
    .subject-com-rank-link.active{
        border-color: #FFE973;
    }
</style>