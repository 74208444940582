<!--  -->
<template>
    <div class='subject-com-rank-item'>
        <div class="rank-item-body text-center" :class="color">
            <!-- <div class="stars-box" v-if="info.userStatus"> -->
            <div class="stars-box" v-if="userStarNum">
                <stars :number="userStarNum"></stars>
            </div>
            <div class="rank-icon"></div>
            <span class="text-large text bold">{{rankNum}}</span>
            <div class="rank-available-animation-box">
                <wave v-if="info.rankAvai"></wave>
            </div>
        </div>
    </div>
</template>

<script>
    import stars from './stars.vue'
    import wave from './wave.vue'

    export default {
        name: '',
        props: {
            info: {
                type: Object,
                default(){
                    return {}
                }
            }
        },
        data() {
            return {

            };
        },
        methods: {

        },
        created() {

        },
        components: {
            stars,
            wave
        },
        computed: {
            userStarNum(){
                let {info = {}} = this
				return info.userStarNum
            },
            color(){
				let {info = {}} = this
				return info.userStatus || info.rankAvai ? 'black' : 'white'
            },
            rankNum(){
				let {info = {}} = this
                return info.puzzleNo
            }
        },
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .subject-com-rank-item{
        .rank-item-body{
            position: relative;
            width: 60px;
            height: 40px;
            .text{
                margin-top: 4px;
            }
        }
        .rank-item-body.black{
            background: url('@{assetsUrl}/img/subject/s5.png');
            background-size: 100% 100%;
            color: #fff;
        }
        .rank-item-body.white{
            background: url('@{assetsUrl}/img/subject/s6.png');
            background-size: 100% 100%;
            color: #333;
        }
        .stars-box{
            position: absolute;
            // bottom: 31px;
            // left: -5px;
            top: -20px;
            left: 0px;
            width: 100%;
        }
        .rank-available-animation-box{
            left: -19px;
            top: 0px;
            position: absolute;
            z-index: -1;
        }
    }
</style>