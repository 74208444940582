<!--  -->
<template>
    <div class='subject-com-stars'>
		<div class="star" v-for="(v, i) in stars" :key="i" :class="[v.class, {'active': v.active}]"></div>
    </div>
</template>

<script>
    export default {
        name: '',
        props: {
            number: [Number, String]
        },
        data() {
            return {
                defaultStar: ['left', 'center', 'right'],
                // defaultStar: ['center', 'left', 'right'],
            };
        },
        methods: {

        },
        created() {

        },
        components: {
        },
        computed: {
            stars(){
				let {defaultStar, userStar} = this
				let rd = []
				for(let i in defaultStar){
					let tem = {class: defaultStar[i]}
					if((Number(i) + 1) <= userStar) tem.active = true
					rd.push(tem)
				}
				return rd
			},
			userStar(){
				let {number} = this
				return number || 0
			},
        },
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .subject-com-stars{
        position: relative;
        display: flex;
        .star{
			width: 22px;
			height: 22px;
			background: url('@{assetsUrl}/img/subject/star_list.png') no-repeat;
			background-size: 100% 100%;
			position: absolute;
			top: 0px;
			left: 0px;
			right: 0px;
			margin: 0px auto;
		}
		.star.active{
			background: url('@{assetsUrl}/img/subject/star_list_active.png') no-repeat;
			background-size: 100% 100%;
		}
		.star.left, .star.right{
			margin-top: 5px;
		}
		.star.left{
			// margin-right: 22px;
			transform: translateX(-22px) rotate(-15deg);
		}
		.star.right{
			// margin-left: 22px;
			transform: translateX(22px) rotate(15deg);
		}
		
		// .star.left{
		// 	background: url('@{assetsUrl}/img/subject/star_right.png');
		// 	background-size: 100% 100%;
		// 	transform: rotateY(180deg);
		// 	margin-right: -4px;
		// }
		// .star.center{
		// 	width: 22px;
		// 	height: 22px;
		// 	background: url('@{assetsUrl}/img/subject/star_center.png');
		// 	background-size: 100% 100%;
        //     margin-top: -3px;
		// }
		// .star.right{
		// 	background: url('@{assetsUrl}/img/subject/star_right.png');
		// 	background-size: 100% 100%;
		// 	margin-left: -4px;
		// }
		// .star.left, .star.right{
		// 	width: 27px;
		// 	height: 27px;
		// }
		// .star.center.active{
		// 	background: url('@{assetsUrl}/img/subject/star_center_active.png');
		// 	background-size: 100% 100%;
		// }
		// .star.right.active, .star.left.active{
		// 	background: url('@{assetsUrl}/img/subject/star_right_active.png');
		// 	background-size: 100% 100%;
		// }
    }
</style>