<!--  -->
<template>
    <div class='subject-list'>
        <div class="list-body">
            <div class="list-item head">
                <div class="head-body">
                    <div class="head-item flex-center cursor-pointer" v-for="v in headListCul" :key="v.value" :class="{'active': v.value == selected.value}" @click="headChange(v)">
                        <span class="text-normal-a" :class="{'bold': v.value == selected.value}">{{v.label}}</span>
                    </div>
                </div>
            </div>
            <div class="list-item content">
                <div class="content-body">
                    <div class="page-link-label next" v-if="!isFirstPage">
                        <rankLinePage :number="2" :active="firstRankData.userStatus"></rankLinePage>
                    </div>
                    <div class="page-link-label last" v-if="!isLastPage">
                        <rankLinePage :number="2" :active="lastRankData.userStatus"></rankLinePage>
                    </div>
                    <div class="rank-item-row" v-for="(s, j) in listCul" :key="j">
                        <!-- :style="{width: `${100 / s.length}%`}" -->
                        <div class="row-item" v-for="(v, k) in s.list" :key="v.puzzleId">
                            <div class="rank-item-box cursor-pointer" @click="gotoAction(v)" v-if="!v.none">
                                <rankItem :info="v"></rankItem>
                            </div>
                            <div class="line-box" :class="{'last': isLast(j, k), 'a': j % 2 == 0, 'b': j % 2 != 0}">
                                <!-- <rankItemLine :active="v.userStatus || v.rankAvai" v-if="k != 0"></rankItemLine> -->
                                <rankItemLine :active="v.rankLineActive" v-if="k != 0"></rankItemLine>
                            </div>
                            <div class="animation-box">
                        </div>
                        </div>
                        <div class="line-link-box" v-if="j != listCul.length - 1" :class="{'left': j % 2 != 0, 'right': j % 2 == 0}">
                            <rankItemLineLink :type="j % 2 != 0 ? 'left' : 'right'" :active="s.active"></rankItemLineLink>
                        </div>
                    </div>
                    <div class="page-item left" v-if="!isFirstPage" @click="pageChange(Number(page) - 1)"
                    @mousedown="$event.currentTarget.classList.add('button-scale-down')"
                    @touchstart="$event.currentTarget.classList.add('button-scale-down')"
                    @touchleave="$event.currentTarget.classList.remove('button-scale-down')"
                    @mouseleave="$event.currentTarget.classList.remove('button-scale-down')"
                    @touchend="$event.currentTarget.classList.remove('button-scale-down')"
                    @mouseup="$event.currentTarget.classList.remove('button-scale-down')"
                    ></div>
                    <div class="page-item right" v-if="!isLastPage" @click="pageChange(Number(page) + 1)"
                    @mousedown="$event.currentTarget.classList.add('button-scale-down')"
                    @touchstart="$event.currentTarget.classList.add('button-scale-down')"
                    @touchleave="$event.currentTarget.classList.remove('button-scale-down')"
                    @mouseleave="$event.currentTarget.classList.remove('button-scale-down')"
                    @touchend="$event.currentTarget.classList.remove('button-scale-down')"
                    @mouseup="$event.currentTarget.classList.remove('button-scale-down')"
                    ></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import apiClass from '@/API/page/subject'
    import rankItem from '../components/rankItem.vue'
    import rankItemLine from '../components/rankItemLine.vue'
    import rankItemLineLink from '../components/rankItemLineLink.vue'
    import rankLinePage from '../components/rankLinePage.vue'

    export default {
        name: '',
        props: {},
        data() {
            return {
                api: new apiClass(),
                head: [
                    {title: 'G0体验训练营', value: 'G0'},
                    {title: 'G1启蒙阶段', value: 'G1'},
                    {title: 'G2提升阶段', value: 'G2'},
                ],
                headList: [],
                routeData: this.$route.params.routeData,
                size: this.PARAMS.subjectPageSize,
                puzzleList: [],
                puzzleCount: 0,
                lineNum: 5,
                pageDestroy: false
            };
        },
        methods: {
            async dataLoad(){
                let {loadParams} = this
                let res = await this.api.getSubjectDirectory(loadParams)
                if(!res) return
                this.puzzleList = res.puzzleList
                this.puzzleCount = res.puzzleCount
            },
            async pageInit(){
                await this.configLoad()
                await this.routeDataCheck()
            },
            async configLoad(){
                let res = await this.api.configLoad('puzzle')
                if(res){
                    this.headList = res
                    // this.headValCheck()
                }
            },
            async routeDataCheck(){
                let params = {}
                let {page, selected: {value: headVal}} = this
                if(page === undefined) params.page = 0
                if(!headVal){
                    let {value} = this.headListCul[0] || {}
                    params.headVal = value
                }
                this.refreshPage({items: params})
                await this._common.nextTick.call(this)
                return true
            },
            refreshPage(opt){
                if(this.pageDestroy) return false
                opt = opt || {}
                let {items, name, type} = opt
                type = type || 'replace'
                return this._common.refreshPage.call(this, {items, name, type})
            },
            setParams(items){
                return this._common.setParams.call(this, items)
            },
            refreshPageAction(opt){
                opt = opt || {}
                let {name, params, type} = opt
                name = name || 'subject-list-full'
                type = type || 'replace'
                return this._common.refreshPageAction.call(this, {name, params, type})
            },
            headChange(val){
                let {value: headVal} = val || {}
                if(!headVal) return
                let items = {
                    headVal,
                    page: 0
                }
                this.refreshPage({items})
            },
            pageChange(page){
                page = Number(page)
                if(page < 0 || page >= this.pageCount || isNaN(page)) return
                let items = {page}
                this.refreshPage({items})
            },
            gotoAction(val){
                let {userStatus, rankAvai, puzzleId} = val || {}
                if(!userStatus && !rankAvai) return this.gotoDisabledNotice()
                if(!puzzleId) return
                let name = 'subject-action'
                let params = {puzzleId}
                params = {
                    routeData: this._common.encodeData(params)
                }
                this._routeHand.goto({name, params})
            },
            gotoDisabledNotice(){
                let message = this.pageText.t1
                this._common.toastOpen({
                    message,
                    getContainer: '.subject-list',
                    className: 'subject-list-toast'
                })
            },
            rankLineActiveGet(i, list){
                let {lineNum} = this
                i = Number(i)
                let index = parseInt((i) / lineNum) + 1
                let f = false
                if(index % 2 != 0) {
                    f = list[i].userStatus ? true : false
                }else{
                    let nextData = list[i + 1]
                    if(!nextData || !nextData.userStatus) f = false
                    else f = true
                }
                return f
            },
            rankAvaiGet(i, list){
                let data = list[i]
                let {userStatus, userStarNum} = data
                if(userStarNum > 0) return false
                return data.userStatus ? true : false
                // if(userStatus){
                //     let {userStatus: nextStatus} = list[Number(i) + 1] || {}
                //     if(!nextStatus) list[i].rankAvai = true
                // }
            },
            isLast(j, k){
                let {listCul: list} = this
                let subList = list[j] || {}
                subList = subList.list || []
                if(j != list.length - 1) return false
                if(j % 2 == 0){
                    return k == subList.length - 1
                }else{
                    return k == 1
                }
            }
        },
        created() {
        },
        components: {
            rankItem,
            rankItemLine,
            rankItemLineLink,
            rankLinePage
        },
        computed: {
            lastRankData(){
                let {listCul: list = []} = this
                let lastData = list[list.length - 1] || {}
                lastData = lastData.list || []
                lastData = lastData[lastData.length - 1] || {}
                return lastData
            },
            firstRankData(){
                let {listCul: list = []} = this
                let firstData = list[0] || {}
                firstData = firstData.list || []
                firstData = firstData[0] || {}
                return firstData
            },
            headListCul(){
                let {headList: list} = this
                list = this._common.deepCopy(list)
                for(let i in list){
                    let {resourceDetail = {}} = list[i]
                    list[i] = {...resourceDetail}
                }
                return list
            },
            usercode(){
                let {
                    app: {usercode} = {}
                } = this.$store.state
                return usercode
            },
            pageCount(){
                let {puzzleCount, size} = this
                return Math.ceil(puzzleCount / size)
            },
            isFirstPage(){
                return this.page <= 0
            },
            isLastPage(){
                return this.page >= (this.pageCount - 1)
            },
            listCul(){
                let {puzzleList: list, lineNum} = this
                list = this._common.deepCopy(list)
                let rd = [], fun = 'push', tem = []
                for(let i in list){
                    let {userStatus} = list[i] || {}
                    // if(userStatus){
                    //     let {userStatus: nextStatus} = list[Number(i) + 1] || {}
                    //     if(!nextStatus) list[i].rankAvai = true
                    // }
                    list[i].rankAvai = this.rankAvaiGet(i, list)
                    fun = rd.length % 2 == 0 ? 'push' : 'unshift'
                    // if(i == list.length - 1) list[i].isLast = true
                    list[i].rankLineActive = this.rankLineActiveGet(i, list)
                    tem[fun](this._common.deepCopy(list[i]))
                    if((Number(i) + 1) % lineNum == 0){
                        let data = {list: tem}
                        let {userStatus} = tem[tem.length - 1] || {}
                        let neIndex = Number(i) + 1
                        if(list[neIndex] && list[neIndex].userStatus) data.active = true
                        else data.active = false
                        rd.push(data)
                        tem = []
                    }
                }
                if(tem.length){
                    let data = {list: tem}
                    let {userStatus} = tem[tem.length - 1] || {}
                    data.active = userStatus ? true : false
                    rd.push(data)
                    tem = []
                }
                for(let i in rd){
                    let {list = []} = rd[i]
                    if(list.length == lineNum) continue
                    let num = lineNum - list.length
                    let fun = i % 2 != 0 ? 'unshift' : 'push'
                    for(let j = 1; j <= num; j++){
                        list[fun]({none: true})
                    }
                    rd[i].list = list
                }
                return rd
            },
            loadParams(){
                let {app: {usercode: user_code} = {}} = this.$store.state
                let {school_id} = this.PARAMS
                let {
                    selected: {courseId: course_id}, 
                    page, size
                } = this
                return {
                    // user_code: '123456',
                    user_code,
                    school_id,
                    course_id,
                    page,
                    size
                }
            },
            routeDataCul(){
                let {routeData} = this
                return this._common.routeDataCul(routeData)
            },
            page(){
                let {page} = this.routeDataCul
                return page
            },
            headVal(){
                let {headVal} = this.routeDataCul
                return headVal
            },
            selected(){
                let {headListCul: list, headVal: val} = this
                let rd = {}
                for(let i in list){
                    if(list[i].value == val){
                        rd = list[i]
                        break
                    }
                }
                return rd
            },
            loadWatch(){
                let {page, selected: {value}} = this
                if(!value || page === undefined || isNaN(Number(page))) return false
                return true
            }
        },
        watch: {
            $route: {
                handler(){
                    this.routeData = this.$route.params.routeData
                },
                deep: true,
                immediate: true
            },
            loadWatch: {
                handler(val, oldval){
                    if(!val) return
                    this.dataLoad()
                },
                deep: true,
                immediate: true
            },
        },
        mounted() {
            this.pageInit()
        },
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {
            this.pageDestroy = true
        }, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .subject-list{
        .list-body{
            .list-item.head{
                margin-left: -@app-padding-side;
                display: flex;
                .head-body{
                    // width: 375px;
                    height: 32px;
                    background: #549EFF;
                    border-radius: 8px;
                    margin: 0px auto;
                    display: flex;
                    align-items: center;
                    overflow: hidden;
                    .head-item.active{
                        background: #fff;
                        color: #333;
                    }
                    .head-item{
                        border-radius: 8px;
                        height: 100%;
                        // width: calc(100% / 3);
                        width: 125px;
                        text-align: center;
                    }
                }
            }
            .list-item.content{
                padding-top: 45px;
                // padding-left: 27px;
                width: 568px;
                position: relative;
                .content-body{
                    padding-left: 27px;
                    padding-right: 19px;
                    position: relative;
                }
                .rank-item-row+ .rank-item-row{
                    margin-top: 60px;
                }
                .rank-item-row{
                    position: relative;
                    display: flex;
                    .row-item+ .row-item{
                        width: 118px;
                        padding-left: 58px;
                        .rank-item-box{
                            position: absolute;
                            top: 0px;
                            left: 58px;
                        }
                    }
                    .row-item{
                        width: 60px;
                        height: 40px;
                        position: relative;
                        .rank-item-box{
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            z-index: 2;
                        }
                        .line-box{
                            position: absolute;
                            top: 13px;
                            left: -20px;
                            z-index: 0;
                            width: 100%;
                        }
                        
                    }
                    .line-link-box{
                        position: absolute;
                        top: 13px;
                        z-index: 1;
                    }
                    .line-link-box.left{
                        left: -27px;
                    }
                    .line-link-box.right{
                        right: -19px;
                    }
                }
                .line-box.last.a{
                    .subjet-com-rank-item-line{
                        border-radius: 0px 14px 14px 0px;
                    }
                }
                .line-box.last.b{
                    .subjet-com-rank-item-line{
                        border-radius: 14px 0px 0px 14px;
                    }
                }
                
                .page-item{
                    width: 40px;
                    height: 40px;
                    position: absolute;
                    top: 58px;
                }
                .page-item.left{
                    left: -40px;
                    background: url('@{assetsUrl}/img/subject/s7.png');
                    background-size: 100% 100%;
                }
                .page-item.right{
                    right: -40px;
                    background: url('@{assetsUrl}/img/subject/s8.png');
                    background-size: 100% 100%;
                }
                .page-link-label{
                    position: absolute;
                }
                .page-link-label.next{
                    top: 13px;
                    left: -67px;
                }
                .page-link-label.last{
                    bottom: 13px;
                    right: -68px;
                }
            }
        }
        .subject-list-toast{
            padding: 12px 21px;
        }
    }
</style>