<!--  -->
<template>
	<div class="wave">
		<div class="wave-box">
			<div class="wave-img-out outside-pic">
				<img :src="`${PARAMS.assetsUrl}/img/wht/waveOutside.png`" alt="">
			</div>
			<div class="wave-img-inside inside-pic">
				<img :src="`${PARAMS.assetsUrl}/img/wht/waveInside.png`" alt="">
			</div>
			<div class="wave-img-out outside-pic-next">
				<img :src="`${PARAMS.assetsUrl}/img/wht/waveOutside.png`" alt="">
			</div>
			<div class="wave-img-inside inside-pic-next">
				<img :src="`${PARAMS.assetsUrl}/img/wht/waveInside.png`" alt="">
			</div>
		</div>
		
	</div>

</template>

<script>

    export default {
        name: 'wave',
        props: {},
        data() {
            return {

            };
        },
        methods: {

        },
        created() {

        },
        components: {
        },
        computed: {},
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less'>
.wave{
	width: 98px;
	height: 28px;
	// position: absolute;
	// left: 50%;
	// bottom: -2px;
	// transform: translateX(-50%);
	// z-index: 99;
	.wave-box{
		position: relative;
		width: 100%;
		height: 100%;
		.wave-img-out{
			width: 100%;
			height: 18px;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%,-50%);
			img{
				width: 100%;
				height: 100%;
			}
		}
		.wave-img-inside{
			width: 76px;
			height: 28px;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%,-50%);
			img{
				width: 100%;
				height: 100%;
			}
		}
	}
	
	
}
/* 第一组水波 */
.inside-pic{
	animation: inside-go-out 3s ease-in-out infinite;
}
.outside-pic{
	animation: outside-go-out 3s ease-out infinite;
}
/* 第二组水波 */
.inside-pic-next{
	animation: inside-go-out-next 3s  infinite;
	
}
.outside-pic-next{
	animation: outside-go-out-next 3s  infinite;
}
@keyframes inside-go-out {
	0% { 
			width: 58px;
			opacity: 0;
		}
	10% {
			opacity: 1;
		}
	35% {
			opacity: 1;
		}
	55% {
			width: 100%;
			opacity: 0;
		}
	100% {
			width: 100%;
			opacity: 0;
		}
}
@keyframes outside-go-out {
	0% { 
			width: 62px;
			opacity: 0;
		}
	10% {
			opacity: 1;
		}
	45% {
			opacity: 1;
		}
	55% {
			width: 100%;
			opacity: 0;
		}
	100% {
			width: 100%;
			opacity: 0;
		}
}
@keyframes inside-go-out-next {
	0% {
			width: 58px;
			opacity: 0;
		}
	50% {
			width: 58px;
			opacity: 0;
		}
	60% {
			opacity: 1;
		}
	70% {
			
			opacity: 1;
		}
	90% {
			width: 100%;
			opacity: 0;
		}
	100% {
			width: 100%;
			opacity: 0;
		}
}
@keyframes outside-go-out-next {
	0% {
			width: 58px;
			opacity: 0;
		}
	40% {
			width: 58px;
			opacity: 0;
		}
	60% {
			opacity: 1;
		}
	70% {
			
			opacity: 1;
		}
	90% {
			width: 100%;
			opacity: 0;
		}
	100% {
			width: 100%;
			opacity: 0;
		}
}
</style>